import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 46.6681 50.3337 L 46.5995 51.4278 L 42.3953 51.4278 Q 42.1972 48.2253 41.717 46.9968 A 2.3107 2.3107 90 0 0 41.507 46.574 A 3.5672 3.5672 90 0 0 39.7416 45.2139 Q 39.1088 44.9745 38.3143 44.8849 A 9.0181 9.0181 90 0 0 37.3028 44.831 L 30.4323 44.831 L 30.4323 61.3055 Q 30.4323 63.8486 31.1764 65.0841 A 2.59 2.59 90 0 0 31.6972 65.7148 Q 32.8872 66.7396 36.7316 66.9013 A 31.4636 31.4636 90 0 0 36.9948 66.9111 L 43.5237 66.9111 A 12.1667 12.1667 90 0 0 47.2953 66.3623 Q 50.8499 65.2073 52.6622 61.6233 A 12.7309 12.7309 90 0 0 52.9569 60.9982 Q 53.7773 59.1523 54.051 56.8283 L 58.2888 56.8283 A 43.2894 43.2894 90 0 1 57.9178 62.38 A 51.702 51.702 90 0 1 57.5027 65.0309 L 56.4772 70.8416 L 17 70.8416 L 17.581 67.0819 A 12.1107 12.1107 90 0 0 19.0538 66.9993 Q 21.2476 66.7298 22.0589 65.5783 Q 22.9857 64.3204 23.0144 60.5929 A 31.2774 31.2774 90 0 0 23.0158 60.3486 L 23.0158 29.1083 Q 23.0158 25.3402 22.0456 23.7246 A 2.2393 2.2393 90 0 0 20.0422 22.5115 A 11.0747 11.0747 90 0 0 18.3678 22.6445 A 13.5184 13.5184 90 0 0 17.4445 22.8195 L 17.8547 18.5467 L 44.3777 18.5467 A 204.099 204.099 90 0 0 48.2382 18.5124 Q 52.2688 18.4361 55.0044 18.1883 A 40.9969 40.9969 90 0 0 56.7166 18 A 57.379 57.379 90 0 1 55.6575 28.9375 Q 55.3838 30.236 55.1787 30.9199 L 51.6927 30.9199 A 28.6475 28.6475 90 0 0 51.4743 28.6918 Q 50.9619 25.0616 49.5731 23.9129 A 4.3015 4.3015 90 0 0 48.2326 23.1723 Q 47.6838 22.9805 47.0349 22.893 A 7.4389 7.4389 90 0 0 46.9929 22.8874 A 21.6223 21.6223 90 0 0 45.7175 22.7649 Q 45.1211 22.7236 44.4442 22.7033 A 47.376 47.376 90 0 0 43.0106 22.6823 L 30.4323 22.6823 L 30.4323 40.9684 L 37.5079 40.9684 A 12.3893 12.3893 90 0 0 38.9807 40.8879 Q 41.1738 40.624 41.9851 39.4991 Q 42.669 38.5765 43.0792 35.1927 L 47.2491 35.1584 L 47.2834 36.97 A 89.2437 89.2437 90 0 1 47.2449 39.4326 Q 47.1322 43.4667 46.6898 50.0082 A 546.3493 546.3493 90 0 1 46.6681 50.3337 Z"
          fill="currentColor"
        />
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 10 5 10 5 80 39 80 72 80 72 10"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
